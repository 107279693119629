import { FaClock } from "react-icons/fa";
import { Box, Text } from "@chakra-ui/react";

function CounsellingComplete({ name }: { name: string }) {
  return (
    <Box px={6} textAlign="center" pt={{ base: "40px", md: "80px" }} pb="40px">
      <FaClock color="#FDC56A" size={80} style={{ margin: "0 auto" }} />
      <Text color="#52BD94" fontFamily="Poppins" fontSize="32px" fontWeight="500" lineHeight="125%" mb="10px">
        Counselling Complete!
      </Text>
      <Text color="var(--Primary-Red900, #0A0103)" fontFamily="Open Sans" fontSize="16px" fontWeight="600" lineHeight="24px">
        Hi {name}, Thank you for completing your counselling session with our NBFC partner.
      </Text>
      <Text color="var(--Primary-Red900, #0A0103)" fontFamily="Open Sans" fontSize="16px" fontWeight="600" lineHeight="24px" mt="10px">
        Please wait while we process your EMI application. We will notify you about the next steps shortly.
      </Text>
    </Box>
  );
}

export default CounsellingComplete;
