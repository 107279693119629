import React, { useState } from "react";
import { CounsellingSlotBookingFinance, EMIPymentStatusCheck } from "../../../api/utils/api/v2/payment";
import { useQuery, useMutation } from "react-query";
import { courDataDynamic } from "../constents";
import { useLocation } from "react-router-dom";
import { Button, Grid, Text, Alert, AlertIcon, Box } from "@chakra-ui/react";
import dayjs from "dayjs";
import useSnackBar from "../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";

function ShowSlots({ isSlotBooked, refetchEMIData }: { isSlotBooked: boolean; refetchEMIData: () => void }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const snackbar = useSnackBar();

  const { mutate: counsellingSlotBookingMutate, isLoading: isBookingCounsellingSlot } = useMutation(CounsellingSlotBookingFinance, {
    onSuccess: async () => {
      snackbar.success("Counselling Slot Booked!");
      refetchEMIData();
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    },
  });

  const { data: emiPaymentStatusData } = useQuery({
    queryKey: ["getEMIPaymentStatus"],
    queryFn: async () => await EMIPymentStatusCheck(course),
    staleTime: 60000,
    retry: 30000,
    enabled: true,
  });

  // Group slots by date
  const slotsByDate = emiPaymentStatusData?.available_slots?.reduce((acc, slot) => {
    if (!acc[slot.slot_date]) {
      acc[slot.slot_date] = [];
    }
    acc[slot.slot_date].push(slot);
    return acc;
  }, {} as Record<string, typeof emiPaymentStatusData.available_slots>);

  return (
    <Box px={6}>
      <Text mt={15} color="var(--Neutral-Grey800, #3B3435)" fontSize="16px" fontWeight={600} fontFamily="Open Sans" lineHeight="24px" pb="10px">
        Select a counselling session with our NBFC partner.
      </Text>

      {isSlotBooked && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          You have missed your previous counselling session. Please book another slot.
        </Alert>
      )}
      <>
        {slotsByDate &&
          Object.entries(slotsByDate)
            .sort(([dateA], [dateB]) => dayjs(dateA).diff(dayjs(dateB)))
            .map(([date, slots]) => {
              if (dayjs(date).isAfter(dayjs(), "day") || dayjs(date).isSame(dayjs(), "day")) {
                return (
                  <div key={date}>
                    <Text fontWeight="bold" mt={4} mb={2}>
                      {dayjs(date).format("dddd, D MMMM")}
                    </Text>
                    <Grid
                      templateColumns={{
                        base: "repeat(3, 1fr)",
                        lg: "repeat(5, 1fr)",
                      }}
                      rowGap={3}
                      columnGap={2}
                    >
                      {slots?.map((slot) => {
                        const slotDateTime = dayjs(`${slot.slot_date} ${slot.slot_time}`);

                        if (dayjs().isBefore(slotDateTime)) {
                          const isSelectedSlot = selectedSlot === slot.slot_time && selectedDate === slot.slot_date;
                          return (
                            <Button
                              key={`${slot.slot_date}-${slot.slot_time}`}
                              p={3}
                              m={2}
                              borderRadius="8px"
                              border={isSelectedSlot ? "1px solid var(--Secondary-Green500, #6FCD9E)" : "1px solid #FDC56A"}
                              textAlign="center"
                              cursor="default"
                              bg={isSelectedSlot ? "#52BD94" : ""}
                              color={isSelectedSlot ? "white" : "gray.500"}
                              onClick={() => {
                                setSelectedSlot(slot.slot_time);
                                setSelectedDate(slot.slot_date);
                              }}
                              _hover={{}}
                              style={{ cursor: "pointer" }}
                            >
                              {slot.slot_time}
                            </Button>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  </div>
                );
              }
              return null;
            })}
        <Button
          onClick={() => {
            if (!selectedSlot) return;
            if (!selectedDate) return;
            counsellingSlotBookingMutate({ counsessling_slot: selectedSlot, course_id: course, session_date: selectedDate });
          }}
          isDisabled={selectedSlot ? false : true}
          textTransform={"capitalize"}
          variant="primary"
          isFullWidth
          size="lg"
          mt={3}
          isLoading={isBookingCounsellingSlot}
        >
          Book counselling
        </Button>
      </>
    </Box>
  );
}

export default ShowSlots;
