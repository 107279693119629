import { Box, Button, Checkbox, FormControl, Radio, RadioGroup, Skeleton, Stack, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import PayInEmi from "./PayInEmi";
import { paymentOptions } from "./constant";
import useIITEntranceOnboardingFullPayment from "../../../api/utils/hooks/useIITMainPaymentInFull";
import { courDataDynamic, kitPrice } from "../constents";
import { useLocation } from "react-router-dom";
import useSnackBar from "../../common/general/SnackBar";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "../../../api/utils/error";
import { CreatePaymentRecordPostForIITEntranceMainEMI, EMIPymentStatusCheck } from "../../../api/utils/api/v2/payment";
import { GetIITEntranceExamStatus } from "../../../api/utils/api/v2/IITEntranceExam";
import EMIFlow from "../EMIFlow";

function EMIPayment() {
  return <div>EMIPayment</div>;
}

function FullPayment() {
  return <div>FullPayment</div>;
}
const FeePayment = ({ refetchIITOnboardingStatus, seatBookingAmount }: { refetchIITOnboardingStatus: () => void; seatBookingAmount: number }) => {
  const [selectedOption, setSelectedOption] = useState("Pay full amount");
  const { startRazorPay } = useIITEntranceOnboardingFullPayment(() => {
    refetchIITOnboardingStatus();
  });

  const snackbar = useSnackBar();
  const { data: emiPaymentStatusData, isLoading: isLoadingEMIPaymentData, refetch: refetchEMIPaymentStatus } = useQuery({
    queryKey: ["getEMIPaymentStatus"],
    queryFn: async () => await EMIPymentStatusCheck(course),
    staleTime: 60000,
    retry: 30000,
    enabled: true,
  });
  const { mutate: emiPaymentMutate, isLoading: isLoadingEMIPayment } = useMutation(CreatePaymentRecordPostForIITEntranceMainEMI, {
    onSuccess: async (data) => {
      snackbar.success("Our team will contact you soon via call for the next steps.");
      refetchEMIPaymentStatus();
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    },
  });

  const [isConsentGiven, setIsConsentGiven] = useState(false);

  const { data: IITEntranceExamStatusData, isLoading: isLoadingIITEntranceExamStatus } = useQuery({
    queryKey: ["getIITEntranceExamStatus"],
    queryFn: async () => await GetIITEntranceExamStatus(course),
    staleTime: 60000,
    retry: 30000,
    enabled: true,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  const [payInEMIForm, setPayInEMIForm] = useState({
    emiOption: course === "IIT_MANDI_ES" ? "12 months" : "8 months",
    currentStatus: "",
    monthlySalary: "",
  });
  const [isUserOptedForKit, setIsUserOptedForKit] = useState(false);
  const [courseFeeAmount, setcourseFeeAmount] = useState(courDataDynamic("ranker")[course].courseFee);

  const isFirstRender = useRef(true);

  useEffect(() => {
    setcourseFeeAmount(
      courDataDynamic("ranker")[course].courseFee -
        seatBookingAmount -
        (IITEntranceExamStatusData?.scholarshipAmount ? courDataDynamic("ranker")[course].courseFee * (IITEntranceExamStatusData?.scholarshipAmount / 100) : 0)
    );
  }, [course, IITEntranceExamStatusData?.scholarshipAmount, seatBookingAmount]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (course !== "IIT_MANDI_ES" && course !== "IIT_MANDI_ES_8_DEC_24") return;
    if (isUserOptedForKit) {
      setcourseFeeAmount((prevCourseFee) => prevCourseFee + kitPrice[course]);
    } else {
      setcourseFeeAmount((prevCourseFee) => prevCourseFee - +kitPrice[course]);
    }
  }, [isUserOptedForKit]);

  if (isLoadingIITEntranceExamStatus || isLoadingEMIPaymentData) {
    return <Skeleton height="100vh" width="100%" />;
  }
  return (
    <Box maxW={{ md: "637px", base: "345px" }} justifyContent="center" alignItems="center" mx="auto" display={"flex"} flexDirection={"column"} gap={"20px"} mt="40px">
      <Box
        w="full"
        flex="1"
        justifyContent="center"
        alignItems="center"
        mx="auto"
        borderRadius="16px"
        border="1px solid #E5E5E5"
        boxShadow="0px 0.986px 2.957px 0px rgba(0, 0, 0, 0.10), 0px 0.986px 1.971px 0px rgba(0, 0, 0, 0.06)"
      >
        <Box borderTopRadius="16px" background="#F2F6FF" px={{ md: "24px", base: "13px" }} py={{ md: "15px", base: "18px" }}>
          <Text color="#000" fontSize="16px" fontWeight={700} fontFamily="Poppins" lineHeight="23px" pb="10px">
            Complete fee payment by
          </Text>
          <Box background="#D6E2FA" maxW="170px" color="#3470E4" fontSize="12px" fontWeight={500} borderRadius="16px" px="10px" py="2px">
            Deadline - {courDataDynamic("ranker")[course].feePaymentDeadline}
          </Box>
        </Box>

        <Box pb="20px">
          <RadioGroup onChange={setSelectedOption} value={selectedOption}>
            <Stack p={5} display="flex" flexDirection="row" gap="20px">
              {paymentOptions.map((option) => (
                <Box
                  key={option.value}
                  border={selectedOption === option.value ? "1px solid #3470E4" : "1px solid #E5E5E5"}
                  mt={option.value === "Pay full amount" ? "0.5rem" : "0px"}
                  borderRadius="12px"
                  px={{ md: "16px", base: "12px" }}
                  py={{ md: "16px", base: "9px" }}
                  flex="1"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Radio value={option.value} fontSize={{ md: "16px", base: "12px" }} color="#21191B" fontWeight={600}>
                    {option.label}
                  </Radio>
                </Box>
              ))}
            </Stack>
          </RadioGroup>

          {selectedOption === "Pay full amount" && (
            //   <Box>
            <Box maxW={{ md: "600px", base: "300" }} color="#fff" borderRadius="12px" bg="#3B3435" mx="auto" px={{ md: "24px", base: "13px" }} py={{ md: "15px", base: "18px" }}>
              <Text fontSize="16px" fontWeight={600} fontFamily="Open Sans">
                Remaining Course Fee
              </Text>
              <Text fontFamily="Open Sans" fontWeight={700} fontSize="33px">
                ₹{courseFeeAmount}
                /-
              </Text>
              {/* </Box> */}
            </Box>
          )}

          {selectedOption === "Pay in EMIs" && (emiPaymentStatusData?.haveOptedForEMI ? <EMIFlow /> : <PayInEmi amount={courseFeeAmount * 1.2} state={payInEMIForm} setState={setPayInEMIForm} />)}
        </Box>
      </Box>
      {selectedOption === "Pay in EMIs" && !emiPaymentStatusData?.haveOptedForEMI ? (
        <Box mt="24px" display="flex">
          <FormControl display="flex" gap="20px" alignItems="flex-start">
            <Checkbox
              name="consent"
              isChecked={isConsentGiven}
              onChange={() => {
                setIsConsentGiven(!isConsentGiven);
              }}
              colorScheme="blue"
              mt="4px"
            ></Checkbox>
            <Text fontSize="14px" fontWeight="400">
              Yes, I give consent to lending partner to review my/co-applicant credit information report with Credit Information Companies such as TransUnion CIBIL <br /> (As you are choosing the EMI
              option as your payment method, a loan will be facilitated through a third-party financial partner. Please note that interest will be applicable and will be borne by the student.)
            </Text>
          </FormControl>
        </Box>
      ) : null}
      {course === "IIT_MANDI_ES" || (course === "IIT_MANDI_ES_8_DEC_24" && (selectedOption === "Pay full amount" || (selectedOption === "Pay in EMIs" && !emiPaymentStatusData?.haveOptedForEMI))) ? (
        <div style={{ display: "flex", gap: "4px", marginTop: "5px", width: "100%" }}>
          <input onChange={(e) => setIsUserOptedForKit(e.target.checked)} checked={isUserOptedForKit} type="checkbox" id="agreeTerms" name="agreeTerms" />
          <p
            style={{
              margin: 0,
              color: "var(--Neutral-Grey800, #3B3435)",
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            I need the Embedded System Toolkit (₹{kitPrice[course]})
          </p>
        </div>
      ) : null}
      {(selectedOption === "Pay in EMIs" && !emiPaymentStatusData?.haveOptedForEMI) || selectedOption === "Pay full amount" ? (
        <Box maxW={{ md: "637px", base: "345px" }} w="full">
          <Button
            isLoading={isLoadingEMIPayment || isLoadingIITEntranceExamStatus}
            disabled={isLoadingEMIPayment || isLoadingIITEntranceExamStatus || (selectedOption === "Pay in EMIs" && !isConsentGiven)}
            onClick={() => {
              if (selectedOption === "Pay full amount") {
                startRazorPay(course, isUserOptedForKit);
              } else {
                emiPaymentMutate({
                  course_id: course,
                  emiOption: payInEMIForm.emiOption,
                  monthlySalary: payInEMIForm.monthlySalary,
                  currentStatus: payInEMIForm.currentStatus,
                  isToolKitNeeded: isUserOptedForKit,
                });
              }
            }}
            variant="primary"
            w="full"
            color="#fff"
          >
            {selectedOption === "Pay full amount" ? "Pay fee now" : "SUBMIT"}
          </Button>
        </Box>
      ) : null}

      <p
        style={{
          color: "var(--Neutral-Grey600, #6C6768)",
          fontFamily: '"Open Sans"',
          fontSize: "14px",
          fontStyle: "italic",
          fontWeight: 600,
          lineHeight: "24px",
          textDecorationLine: "underline",
          textDecorationStyle: "solid",
          textDecorationSkipInk: "auto",
          textDecorationThickness: "auto",
          textUnderlineOffset: "auto",
          textUnderlinePosition: "from-font",
        }}
      >
        Disclaimer: All payments are non-refundable. This policy ensures the sustained quality and provision of our services
      </p>
    </Box>
  );
};

export default FeePayment;
