import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { EMIPymentStatusCheck, SaveUserDocumentConformationForEMI } from "../../../api/utils/api/v2/payment";
import { useLocation } from "react-router-dom";
import { courDataDynamic } from "../constents";
import ShowSlots from "./ShowSlots";
import SlotsBooked from "./SlotsBooked";
import CounsellingComplete from "./CounsellingComplete";
import AmountDisbursed from "./AmountDisbursed";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import dayjs from "dayjs";
import DocumentModal from "./DocumentModal";

function EMIFlow() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  const profile = useProfile();
  const [shouldDocumentConfirmationModalOpen, setShouldDocumentConfirmationModalOpen] = useState(false);

  const { data: emiPaymentStatusData, refetch: refetchEMIData } = useQuery({
    queryKey: ["getEMIPaymentStatus"],
    queryFn: async () => await EMIPymentStatusCheck(course),
    staleTime: 60000,
    retry: 30000,
    enabled: true,
    onError(err) {
      console.log("This method should be called only once", err);
    },
    onSuccess: (data) => {
      console.log("This method should be called only once 1");
      if (data?.haveAlreadyBookedSlot && data.haveFilledConfirmation === false) {
        console.log("This method should be called only once 2");
        setShouldDocumentConfirmationModalOpen(true);
      }
    },
    // onSuccess(data) {
    //   console.log("This method should be called only once 1");
    //   if (data?.haveAlreadyBookedSlot && data.haveFilledConfirmation === false) {
    //     console.log("This method should be called only once 2");
    //     setShouldDocumentConfirmationModalOpen(true);
    //   }
    // },
  });

  const { mutate: saveDocumentConfirmation, isLoading: isSavingDocumentConfirmation } = useMutation({
    mutationFn: async () => {
      return await SaveUserDocumentConformationForEMI({ course_id: course });
    },
    onSuccess: () => {
      setShouldDocumentConfirmationModalOpen(false);
      refetchEMIData();
    },
    onError: (error) => {
      console.error("Failed to save document confirmation:", error);
    },
  });

  useEffect(() => {
    if (emiPaymentStatusData?.haveAlreadyBookedSlot && emiPaymentStatusData.haveFilledConfirmation === false) {
      setShouldDocumentConfirmationModalOpen(true);
    }
  }, [emiPaymentStatusData]);

  return (
    <div>
      {!emiPaymentStatusData?.haveAlreadyBookedSlot && <ShowSlots refetchEMIData={refetchEMIData} isSlotBooked={false} />}
      {emiPaymentStatusData?.haveAlreadyBookedSlot &&
        !emiPaymentStatusData.currentStatus &&
        (dayjs().isBefore(dayjs(`${emiPaymentStatusData.slot_date} ${emiPaymentStatusData.slot_time}`)) ||
        dayjs().isSame(dayjs(`${emiPaymentStatusData.slot_date} ${emiPaymentStatusData.slot_time}`)) ? (
          <SlotsBooked slot_date={emiPaymentStatusData.slot_date || ""} slot_time={emiPaymentStatusData.slot_time || ""} isNoShow={false} />
        ) : (
          <ShowSlots refetchEMIData={refetchEMIData} isSlotBooked={emiPaymentStatusData?.haveAlreadyBookedSlot} />
        ))}
      {emiPaymentStatusData?.haveAlreadyBookedSlot && emiPaymentStatusData.currentStatus === "complete" && <CounsellingComplete name={profile.data?.name || ""} />}
      {emiPaymentStatusData?.haveAlreadyBookedSlot && emiPaymentStatusData.currentStatus === "amount-disbursed" && <AmountDisbursed name={profile.data?.name || ""} />}
      <DocumentModal
        isSavingDocumentConfirmation={isSavingDocumentConfirmation}
        isOpen={shouldDocumentConfirmationModalOpen}
        onClose={() => {
          setShouldDocumentConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          saveDocumentConfirmation();
        }}
      />
    </div>
  );
}

export default EMIFlow;
