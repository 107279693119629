import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, UnorderedList, ListItem } from "@chakra-ui/react";

interface DocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSavingDocumentConfirmation: boolean;
}

const DocumentModal: React.FC<DocumentModalProps> = ({ isOpen, onClose, onConfirm, isSavingDocumentConfirmation }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalHeader fontFamily="Poppins" fontSize="20px">
          Document Checklist
        </ModalHeader>
        <ModalBody>
          <Text mb={4} fontFamily="Poppins">
            Please ensure you have the following documents ready for the EMI counselling session:
          </Text>
          <UnorderedList spacing={3} fontFamily="Poppins">
            <ListItem>
              PAN Card <span style={{ fontSize: "12px", color: "gray" }}>Image/soft copy</span>
            </ListItem>
            <ListItem>
              Aadhar Card <span style={{ fontSize: "12px", color: "gray" }}>Image/soft copy of both front and back</span>
            </ListItem>
            <ListItem>Latest 3 months Bank Statement</ListItem>
          </UnorderedList>
          <Text fontWeight="bold" mt={5}>
            Mandatory Documents Required for the EMI Process is{" "}
            <a
              style={{ color: "blue", textDecoration: "underline" }}
              href="https://masai-website-images.s3.ap-south-1.amazonaws.com/Mandatory+Documents+Required+for+EMI+Process.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </Text>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button isLoading={isSavingDocumentConfirmation} isDisabled={isSavingDocumentConfirmation} bg="#52BD94" color="white" _hover={{ bg: "#3da77e" }} onClick={onConfirm}>
            I Have All Documents
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DocumentModal;
