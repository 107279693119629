import { Box, Text, Button } from "@chakra-ui/react";
import Lottie from "react-lottie-player";
import dayjs from "dayjs";
import successLottie from "../../../assets/successLottie.json";

type SlotsBookedProps = {
  /** Whether the user missed their slot */
  isNoShow: boolean;
  /** Date of the booked slot in YYYY-MM-DD format */
  slot_date: string;
  /** Time of the booked slot in HH:mm format */
  slot_time: string;
  /** Optional URL for joining the meeting */
  meetingList?: string;
};

const SlotsBooked = ({ isNoShow, slot_date, slot_time, meetingList }: SlotsBookedProps) => (
  <Box h="full" pt={{ base: "40px", md: "80px" }} pb="40px" textAlign={"center"}>
    <Box m={"auto"} mb={{ base: "20px", md: "ms-32" }} w={{ base: "97px", md: "112px" }} h={{ base: "104px", md: "120px" }}>
      <Lottie loop={false} animationData={successLottie} play style={{ width: "100%" }} />
    </Box>
    {!isNoShow && (
      <Text color="var(--Primary-Red900, #0A0103)" fontFamily="Open Sans" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px" mb="8px">
        Session scheduled for :{" "}
        <span style={{ color: "#3470E4", fontWeight: 700 }}>
          {dayjs(slot_date).format("Do MMM")}, {slot_time}
        </span>
      </Text>
    )}
    <Text color="var(--Extended-Blue-400, #5D8DE9)" fontFamily="Open Sans" fontSize="16px" fontStyle="italic" fontWeight="600" lineHeight="24px">
      A calendar invite has been sent to your email.
    </Text>
    {meetingList && !isNoShow && (
      <Button
        isDisabled={!dayjs().isAfter(dayjs(`${slot_date} ${slot_time}`).subtract(10, "minute"))}
        onClick={() => {
          window.open(meetingList);
        }}
        variant="primary"
        size="lg"
        mt={7}
      >
        Join Meeting
      </Button>
    )}
  </Box>
);

export default SlotsBooked;
