import { FaCheckCircle } from "react-icons/fa";
import { Box, Text } from "@chakra-ui/react";

function AmountDisbursed({ name }: { name: string }) {
  return (
    <Box textAlign="center" pt={{ base: "40px", md: "80px" }} pb="40px">
      <FaCheckCircle color="#52BD94" size={80} style={{ margin: "0 auto" }} />
      <Text color="#52BD94" fontFamily="Poppins" fontSize="32px" fontWeight="500" lineHeight="125%" mb="10px">
        Loan Disbursed Successfully!
      </Text>
      <Text color="var(--Primary-Red900, #0A0103)" fontFamily="Open Sans" fontSize="16px" fontWeight="600" lineHeight="24px">
        Hi {name}, Your loan has been successfully disbursed by our NBFC partner.
      </Text>
      <Text color="var(--Primary-Red900, #0A0103)" fontFamily="Open Sans" fontSize="16px" fontWeight="600" lineHeight="24px" mt="10px">
        You can now proceed with your enrollment process. Welcome to the program!
      </Text>
    </Box>
  );
}

export default AmountDisbursed;
